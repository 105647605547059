import React, { Component } from "react";
import {
  Segment,
  Grid,
  Table,
  Header,
  Divider,
  Icon,
  Select,
  Form,
  Dropdown,
  Button,
  Checkbox,
  Modal,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import feathers from "../../feathers-client";
import swal from "sweetalert";
import _ from "lodash"
import moment from "moment";
import sabaths from "../../utils/sabaths"
import localforage from "localforage";

import { currentQuarter, formatPercentage } from '../../utils/functions';

class ReporteClase extends Component  {
  state = {
    alumnos: [],
    clases: [],
    claseSelected: 2,
    loading: false,

    loadingStudents: false,
    loadingVisits: false,
    records: [],
    visits: [],
    newVisit: {
      full_name: "",
      cellphone: ""
    },
    newStudent: {
      full_name: "",
      clase_id: 0
    },
    isNewRecord: false,
    isModalOpen: false,
    isModalStudentOpen: false,
    currentUser: {},
    dateSelected: "",
    clase_id: 1,
    sabaths: [],

    loadingGeneral: true,
    asistenceToTable: [],
    studyToTable: [],
    verseToTable: [],
  }

  componentDidMount() {
    localforage.getItem('claseSelected').then((res) => {
      this.getClases(res)
    })
    const sabathsFiltered = sabaths.filter(s => s.quarter === currentQuarter)
    this.setState({ 
      sabaths: sabathsFiltered,
      dateSelected: moment().day(6).format("YYYY-MM-DD")
    })
    this.getReporteSemaforoClase()
  }

  //function reporte semaforo
  getReporteSemaforoClase = (claseId) => {
    this.setState({ loadingGeneral: true })
    feathers.service("reporte-clase").get(claseId)
      .then(res => {
        this.setState({
          asistenceToTable: res[0],
          studyToTable: res[1],
          verseToTable: res[2],
          resto: res.resto,
          loadingGeneral: false
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  // functions alumnos
  getClases = (claseDefault = false) => {
    const clases = feathers.service("clases");
    clases.find({
      query: {
        $limit: 1000
      }
    })
      .then(res => {
        const clases = res.data.map((c, i) => ({
          key: i,
          text: c.name,
          value: c.id
        }))
        const claseSelected = claseDefault ? claseDefault : clases[0].value
        this.setState({
          clases, 
          clasesLoading: false, 
          claseSelected: claseSelected
        })
        this.getAlumnos(claseSelected)
        this.getReporteSemaforoClase(claseSelected)
        this.getRecords(claseSelected)
        this.getVisits(claseSelected)
      })
  }

  getAlumnos = (claseId) => {
    this.setState({loading: true})
    const clases = feathers.service("students");
    clases.find({
      query: {
        clase_id: claseId,
        $limit: 1000
      }
    })
      .then(res => {
        const alumnos_sorted = [...res.data.filter(d => d.rol === 'MAESTRO'), ...res.data.filter(d => d.rol === 'ALUMNO')]
        this.setState({alumnos: alumnos_sorted, loading: false})
      })
  }

  handleDelete = async (Id) => {
    this.setState({ loading: true })
    await swal({
      title: "¿Seguro desea eliminar el alumno?",
      text:
        "Esta operación es irreversible",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async willDelete => {
      if (willDelete) {
        await feathers
          .service("students")
          .remove(Id)
          .then(res => {
            swal("Alumno eliminado correctamente", { icon: "success", timer: 2000 });
            this.getAlumnos(this.state.claseSelected);
          })
          .catch(err => {
            console.log(err);
            swal("Ocurrió un error. Intente más tarde", { icon: "error" });
          });
      }
    });
    this.setState({ loading: false })
  };

  handleChangeClass = (event, result) => {
    const { name, value } = result || event.target;
    this.setState({ [name]: value })
    this.getAlumnos(value)
    this.getVisits(value)
    this.getRecords(value)
    this.getReporteSemaforoClase(value)
    localforage.setItem("claseSelected", value)
  }

  // functions pase lista
  getVisits = id => {
    const visits = feathers.service("visits")
    visits.find({
      query: {
        clase_id: id,
        week_date: this.state.dateSelected,
        $limit: 1000
      }
    })
    .then(res => {
      this.setState({ visits: res.data })
    })
  }

  getRecords = id => {
    const records = feathers.service('records')
    records.find({
      query: {
        clase_id: id,
        week_date: this.state.dateSelected,
        $limit: 1000
      }
    })
    .then(res => {
      const recordsSorted = [...res.data.filter(d => d.student.rol === 'MAESTRO'), ...res.data.filter(d => d.student.rol === 'ALUMNO')]
      this.setState({ records: recordsSorted }, () => {
        if(res.data.length === 0) {
          this.getRecordsFromStudents(id)
        }
      })
    })    
  }

  getRecordsFromStudents = id => {
    const students = feathers.service('students')
      students.find({
        query: {
          clase_id: id,
          $limit: 1000
        }
      })
      .then(res => {
        const newRecords = res.data.map(s => ({
          "student_id": s.id,
          "student" : {
            "full_name": s.full_name
          },
          "rol": s.rol,
          "trimestre": currentQuarter,
          "clase_id": s.clase_id,
          "week_date": this.state.dateSelected, //change by momentjs
          "study": "FALSE",
          "assistence": "FALSE",
          "verse": "FALSE",
        }))
        const newRecordsSorted = [...newRecords.filter(d => d.rol === 'MAESTRO'), ...newRecords.filter(d => d.rol === 'ALUMNO')]
        this.setState({ records: newRecordsSorted, isNewRecord: true })
      })
  }

  handleSubmit = async () => {
    this.setState({ loadingStudents: true })
    const records_temp = JSON.parse(JSON.stringify(this.state.records))
    for (let rec of records_temp) {
      delete rec.student
      delete rec.created_at
      delete rec.rol
      rec.week_date = moment(rec.week_date).day(6).format("YYYY-MM-DD")
      console.log("🚀 ~ ReporteClase ~ handleSubmit= ~ rec.week_date:", rec.week_date)

      if(!rec.id) {
        await feathers.service('records')
        .create(rec)
        .then(res => {
        })
      } else {
        await feathers.service('records')
        .patch(rec.id, rec)
        .then(res => {
        })
      }
      
    }
    this.setState({ isNewRecord: false, loadingStudents: false })
    swal(
      "Registro guardado",
      "El registro de ha guardado correctamente.",
      "success",
      {timer: 1500}
    );
  }

  handleChangeCheckbox = (e, { checked, name, idCheck }) => {
    const tempRecords = [...this.state.records]
    tempRecords[idCheck] = {
      ...tempRecords[idCheck],
      [name]: checked ? "TRUE" : "FAlSE",
    }
    this.setState({records: tempRecords})
  }

  handleAddVisit = async () => {
    this.setState({ loadingVisits: true })
    const visits_temp = [...this.state.visits]
    const new_visit = {
      ...this.state.newVisit,
      week_date: this.state.dateSelected, //change by momentjs
      clase_id: this.state.claseSelected
    }
    visits_temp.push(new_visit)
    await feathers.service('visits')
      .create(new_visit)
      .then(res => {
      })
    this.setState({
      loadingVisits: false,
      visits: visits_temp, 
      isModalOpen: false, 
      newVisit: {
        full_name: "",
        cellphone: ""
      }
    })
  }

  handleAddStudent = async () => {
    this.setState({ loadingStudents: true })
    const records_temp = [...this.state.records]
    const new_student = {
      ...this.state.newStudent,
      clase_id: this.state.claseSelected
    }

    await feathers.service('students')
    .create(new_student)
    .then(async res => {
      const new_record = {
        student_id: res.id,
        student: {
          full_name: res.full_name
        },
        clase_id: new_student.clase_id,
        assistence: "FALSE",
        study: "FALSE",
        verse: "FALSE",
        week_date: this.state.dateSelected
      }
      records_temp.push(new_record)
      this.setState({ 
        records: records_temp, 
        isModalStudentOpen: false,
        newStudent: {
          full_name: "",
          clase_id: 0
        }
      })
    })
    this.setState({ loadingStudents: false })
  
  }

  handleChangeVisitForm = (e) => {
    this.setState({
      newVisit: {
        ...this.state.newVisit,
        [e.target.name]: e.target.value
      }
    })
  }

  handleChangeStudentForm = (e) => {
    this.setState({
      newStudent: {
        ...this.state.newStudent,
        [e.target.name]: e.target.value
      }
    })
  }

  handleDelete = async (index, visit_id) => {
    if(visit_id) {
      await feathers
        .service("visits")
        .remove(visit_id)
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    }
    const visits_temp = [...this.state.visits]
    visits_temp.splice(index, 1)
    this.setState({ visits: visits_temp })
  }

  handleChangeDate = (event, result) => {
    const { name, value } = result || event.target;
    this.setState({ [name]: value, dateSelected: value }, () => {
      this.getRecords(this.state.claseSelected)
      this.getVisits(this.state.claseSelected)
    })
  }

  handleDeleteRecords = async () => {
    const records = feathers.service('records')
    records.remove(null, { query: {
      clase_id: this.state.claseSelected,
      week_date: this.state.dateSelected,
    }})
    .then(res => {
      this.getRecords(this.state.claseSelected)
      console.log("🚀 ~ file: index.jsx:378 ~ ReporteClase ~ handleDeleteRecords ~ res:", res)
    })
    .catch(err => {
      console.log("🚀 ~ file: index.jsx:381 ~ ReporteClase ~ handleDeleteRecords ~ err:", err)
      return 
    })
  }

  render() {
    const { sabaths, asistenceToTable, studyToTable, verseToTable, loadingGeneral } = this.state;
    return (
      <>
      {/* SELECT CLASE */}
      <Segment>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h1" textAlign="left">
              <Header.Content>Seleccionar Clase</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Row columns={4} textAlign="left">
            <Grid.Column>
              <Form.Field>
                <Select
                  label="Clase"
                  value={this.state.claseSelected}
                  options={this.state.clases}
                  name="claseSelected"
                  onChange={this.handleChangeClass}
                />
              </Form.Field>
              
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      {/* LISTADO DE ALUMNOS */}
      <Segment loading={this.state.loading}>
        <Grid>
          <Grid.Column width={12}>
            <Header as="h3">
              <Icon name="list" />
              <Header.Content>Listado de Alumnos</Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column width={4}>
            <Link to="crear-alumno">
            <Icon name='plus' size="big" color="green"/>
            </Link>
          </Grid.Column>
        </Grid>

        <Divider />

        <Grid style={{ padding: "0 20px"}}>
          <Grid.Row width={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Rol</Table.HeaderCell>
                  <Table.HeaderCell>Acciones</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.alumnos.map((a, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{i + 1}</Table.Cell>
                    <Table.Cell>{a.full_name}</Table.Cell>
                    <Table.Cell>{a.rol}</Table.Cell>
                    <Table.Cell>
                      <Link to="#" onClick={() => this.handleDelete(a.id)}>
                        <Icon name="trash" color="grey" /> Eliminar
                      </Link>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Link to={`/editar-alumno/${a.id}`}>
                        <Icon name="pencil" color="grey" /> Editar
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Segment>
      
      {/* PASE DE LISTA */}
        <Segment>
          <Grid>
            <Grid.Column width={12}>
              <Header as="h3">
                <Icon name="list" />
                <Header.Content>Pase de Lista</Header.Content>
              </Header>
            </Grid.Column>
          </Grid>

          <Divider />

          <Grid>
          <Grid.Row columns={6}>
            <Grid.Column>
              <Header as="h3">
                <Icon name="calendar" />
                <Header.Content>
                  <Dropdown
                    name="dateSelected"
                    options={this.state.sabaths}
                    value={this.state.dateSelected}
                    onChange={this.handleChangeDate}
                  />
                </Header.Content>
              </Header>
            </Grid.Column>

            <Grid.Column textAlign="left" style={{ paddingTop: "5px" }}>
            <Header as="h5" textAlign="left">
              { this.state.dateSelected }
            </Header>
          </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3} className="help-icons">
            <Grid.Column>
              <p>
                <Icon name="clock" />
                Asistencia
              </p>
            </Grid.Column>
            <Grid.Column>
              <p>
                <Icon name="book" />
                Estudio Diario (min 6 días)
              </p>
            </Grid.Column>
            <Grid.Column>
              <p>
                <Icon name="check" />
                Versículo
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        
          <Segment loading={this.state.loadingStudents}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={12}>
                  <Header as="h3" textAlign="left" >
                    <Header.Content>
                      Lista de Alumnos
                    </Header.Content>
                  </Header>
                </Grid.Column>
                
                <Grid.Column width={4} textAlign="right">
                  <Button 
                    size="mini"
                    negative
                    circular
                    icon="trash"
                    style={{ padding: "none" }}
                    onClick={this.handleDeleteRecords}
                  />
                  <Button
                    size="mini" 
                    positive 
                    circular 
                    icon="plus" 
                    style={{ padding: "none" }}
                    onClick={() => this.setState({ isModalStudentOpen: true })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={10}>Alumno</Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <Icon name="clock"/>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <Icon name="book"/>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <Icon name="check"/>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.state.records.map((r, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{r.student.full_name}</Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          name="assistence"
                          idCheck={i}
                          checked={r.assistence === "TRUE"}
                          onClick={this.handleChangeCheckbox}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox 
                          name="study"
                          idCheck={i}
                          checked={r.study === "TRUE"}
                          onClick={this.handleChangeCheckbox}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox 
                          name="verse"
                          idCheck={i}
                          checked={r.verse === "TRUE"}
                          onClick={this.handleChangeCheckbox}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell colSpan='4'>
                    <Button
                      content="Guardar Registro" 
                      primary
                      onClick={this.handleSubmit}
                    >
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>

          <Segment loading={this.state.loadingVisits}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column width={12}>
                  <Header as="h3" textAlign="left" >
                    <Header.Content>
                      Lista de Visitas
                    </Header.Content>
                  </Header>
                </Grid.Column>
                
                <Grid.Column width={4} textAlign="right">
                  <Button
                    size="mini" 
                    positive 
                    circular 
                    icon="plus" 
                    style={{ padding: "none" }}
                    onClick={() => this.setState({ isModalOpen: true })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Celular</Table.HeaderCell>
                  <Table.HeaderCell>
                    <Icon name="trash"/>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.state.visits.map((v, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{i + 1}</Table.Cell>
                      <Table.Cell>
                        {v.full_name}
                      </Table.Cell>
                      <Table.Cell>
                        {v.cellphone}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon
                          name="trash"
                          color="red"
                          onClick={() => this.handleDelete(i, v.id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                }
              </Table.Body>
            </Table>
          </Segment>

          {/* Modal para añadir visita */}
          <Modal
            closeIcon
            open={this.state.isModalOpen}
            onClose={() => this.setState({ isModalOpen: false })}
          >
            <Modal.Header>
              Añadir Visita
            </Modal.Header>
            <Modal.Content>
              <Segment loading={this.state.loadingVisits}>
                <Form>
                  <Form.Input 
                    required
                    fluid
                    label="Nombre"
                    name="full_name"
                    value={this.state.newVisit.full_name}
                    onChange={this.handleChangeVisitForm}
                  />
                  <Form.Input 
                    required
                    fluid
                    label="Celular"
                    name="cellphone"
                    value={this.state.newVisit.cellphone}
                    onChange={this.handleChangeVisitForm}
                  />
                </Form>
              </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Segment loading={this.state.loadingVisits}>
                <Button
                  disabled={this.state.newVisit.full_name === "" || this.state.newVisit.cellphone === "" }
                  primary
                  content="Añadir"
                  onClick={this.handleAddVisit}
                />
              </Segment>
            </Modal.Actions>
          </Modal>

          {/* Modal para añadir alumno */}
          <Modal
            closeIcon
            open={this.state.isModalStudentOpen}
            onClose={() => this.setState({ isModalStudentOpen: false })}
          >
            <Modal.Header>
              Añadir Alumno
            </Modal.Header>
            <Modal.Content>
                <Segment loading={this.state.loadingStudents}>
                  <Form>
                    <Form.Input 
                      required
                      fluid
                      label="Nombre"
                      name="full_name"
                      value={this.state.newStudent.full_name}
                      onChange={this.handleChangeStudentForm}
                    />
                  </Form>
                </Segment>
            </Modal.Content>
            <Modal.Actions>
              <Segment loading={this.state.loadingStudents}>

                <Button
                  disabled={this.state.newStudent.full_name === "" }
                  primary
                  content="Añadir"
                  onClick={this.handleAddStudent}
                />
              </Segment>
            </Modal.Actions>
          </Modal>
          
        </Segment>

        <Segment loading={loadingGeneral}>
          <Grid>
            <Grid.Column width={12}>
              <Header as="h1">
                <Icon name="file" />
                  <Header.Content>Reporte general</Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
          
          <Divider />

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                </Table.HeaderCell>
                {
                  sabaths.map((m, index) => 
                    <Table.HeaderCell key={index}>
                      {m.text.substring(8,10)}
                    </Table.HeaderCell>
                  ) 
                }
                <Table.HeaderCell>
                  Promedio General
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell><Icon name="clock" /><strong>A</strong></Table.Cell>
                {
                  asistenceToTable.map((cell, index) => (
                      cell === "N/A" 
                        ?
                          <Table.Cell key={index}></Table.Cell>
                        :
                          <Table.Cell key={index} positive={cell>=0.80} warning={cell>=0.60&&cell<0.80} negative={cell<0.60}>
                            {formatPercentage(cell) + "%"}
                          </Table.Cell>
                  ))
                }
              </Table.Row>
              <Table.Row>
                <Table.Cell><Icon name="book" /><strong>E</strong></Table.Cell>
                {
                  studyToTable.map((cell) => (
                      cell === "N/A" 
                        ?
                          <Table.Cell></Table.Cell>
                        :
                          <Table.Cell positive={cell>=0.80} warning={cell>=0.60&&cell<0.80} negative={cell<0.60}>
                            {formatPercentage(cell) + "%"}
                          </Table.Cell>
                  ))
                }
              </Table.Row>
              <Table.Row>
                <Table.Cell><Icon name="check" /><strong>V</strong></Table.Cell>
                {
                  verseToTable.map((cell) => (
                      cell === "N/A" 
                        ?
                          <Table.Cell></Table.Cell>
                        :
                          <Table.Cell positive={cell>=0.80} warning={cell>=0.60&&cell<0.80} negative={cell<0.60}>
                            {formatPercentage(cell) + "%"}
                          </Table.Cell>
                  ))
                }
              </Table.Row>

            </Table.Body>
          </Table>
        </Segment>
      </>
    );
  }
}

export default ReporteClase;