const quarters = [
  {
    key: "2023Q4",
    text: "Trim 4 2023",
    value: "2023Q4"
  },
  {
    key: "2024Q1",
    text: "Trim 1 2024",
    value: "2024Q1"
  },
  {
    key: "2024Q2",
    text: "Trim 2 2024",
    value: "2024Q2"
  },
  {
    key: "2024Q3",
    text: "Trim 3 2024",
    value: "2024Q3"
  }
];

export default quarters;