const sabaths = [
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2023-10-07",
    quarter: "2023Q4"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2023-10-14",
    quarter: "2023Q4"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2023-10-21",
    quarter: "2023Q4"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2023-10-28",
    quarter: "2023Q4"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2023-11-04",
    quarter: "2023Q4"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2023-11-11",
    quarter: "2023Q4"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2023-11-18",
    quarter: "2023Q4"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2023-11-25",
    quarter: "2023Q4"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2023-12-02",
    quarter: "2023Q4"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2023-12-09",
    quarter: "2023Q4"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2023-12-16",
    quarter: "2023Q4"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2023-12-23",
    quarter: "2023Q4"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2023-12-30",
    quarter: "2023Q4"
  },
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2024-01-06",
    quarter: "2024Q1"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2024-01-13",
    quarter: "2024Q1"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2024-01-20",
    quarter: "2024Q1"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2024-01-27",
    quarter: "2024Q1"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2024-02-03",
    quarter: "2024Q1"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2024-02-10",
    quarter: "2024Q1"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2024-02-17",
    quarter: "2024Q1"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2024-02-24",
    quarter: "2024Q1"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2024-03-02",
    quarter: "2024Q1"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2024-03-09",
    quarter: "2024Q1"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2024-03-16",
    quarter: "2024Q1"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2024-03-23",
    quarter: "2024Q1"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2024-03-30",
    quarter: "2024Q1"
  },
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2024-04-06",
    quarter: "2024Q2"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2024-04-13",
    quarter: "2024Q2"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2024-04-20",
    quarter: "2024Q2"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2024-04-27",
    quarter: "2024Q2"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2024-05-04",
    quarter: "2024Q2"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2024-05-11",
    quarter: "2024Q2"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2024-05-18",
    quarter: "2024Q2"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2024-05-25",
    quarter: "2024Q2"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2024-06-01",
    quarter: "2024Q2"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2024-06-08",
    quarter: "2024Q2"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2024-06-15",
    quarter: "2024Q2"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2024-06-22",
    quarter: "2024Q2"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2024-06-29",
    quarter: "2024Q2"
  },
  {
    key: "Lección 1",
    text: "Lección 1",
    value: "2024-07-06",
    quarter: "2024Q3"
  },
  {
    key: "Lección 2",
    text: "Lección 2",
    value: "2024-07-13",
    quarter: "2024Q3"
  },
  {
    key: "Lección 3",
    text: "Lección 3",
    value: "2024-07-20",
    quarter: "2024Q3"
  },
  {
    key: "Lección 4",
    text: "Lección 4",
    value: "2024-07-27",
    quarter: "2024Q3"
  },
  {
    key: "Lección 5",
    text: "Lección 5",
    value: "2024-08-03",
    quarter: "2024Q3"
  },
  {
    key: "Lección 6",
    text: "Lección 6",
    value: "2024-08-10",
    quarter: "2024Q3"
  },
  {
    key: "Lección 7",
    text: "Lección 7",
    value: "2024-08-17",
    quarter: "2024Q3"
  },
  {
    key: "Lección 8",
    text: "Lección 8",
    value: "2024-08-24",
    quarter: "2024Q3"
  },
  {
    key: "Lección 9",
    text: "Lección 9",
    value: "2024-08-31",
    quarter: "2024Q3"
  },
  {
    key: "Lección 10",
    text: "Lección 10",
    value: "2024-09-07",
    quarter: "2024Q3"
  },
  {
    key: "Lección 11",
    text: "Lección 11",
    value: "2024-09-14",
    quarter: "2024Q3"
  },
  {
    key: "Lección 12",
    text: "Lección 12",
    value: "2024-09-21",
    quarter: "2024Q3"
  },
  {
    key: "Lección 13",
    text: "Lección 13",
    value: "2024-09-28",
    quarter: "2024Q3"
  }
]

export default sabaths;